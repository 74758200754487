section
  .testimonials-column
    overflow: hidden

  .testimonials-container
    display: block
    width: 1000em
    transition: margin 200ms

    .testimonials-box
      display: block
    
      background-color: $br
      border-radius: 1em
      padding: 2em
      
      float: left

      width: 25em
      height: 16em

      margin-right: 2em


      p
        font-size: 0.8em
        
        &.testimonials-stars
          margin-top: 0
          span
            color: $g
            padding-right: 0.25em

          span.yellow
            color: #FFC136

      h3
        font-size: 1.25em

      img
        border-radius: 100%
        width: 4em
        vertical-align: middle
        margin-right: 1em
      

  &.brown
    .testimonials-container
      .testimonials-box
        background-color: $w

