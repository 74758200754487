.button-group
  display: flex
  align-items: center

  *:last-child
    margin-right: 0

  a
    margin: 0 0.25em
    
    svg
      font-size: 1.25em

  button
    margin: 0 1em

  a, button
    &:first-child
      margin-left: 0
      
    &:last-child
      margin-right: 0

.button-rounded
  svg
    padding-right: 0.3em
  
.button-rounded
  position: relative
  padding: 1em 2em

  border: none
  border-radius: 1em
  
  color: $w
  background-color: $bb
  font-weight: bold
  font-size: 1em

  &:disabled
    background-color: $mg
    
    &:hover
      background-color: $mg

  &:hover
    color: $w
    background-color: darken($bb, 10%)

  &:active
    top: 2px

  &:focus
    outline: none

  &.button-size-m
    font-size: 0.8em
    padding: 1.25em 1em

  &.button-white
    background-color: $w
    color: $b

.button-transparent
  background-color: transparent
  border: none
  color: inherit
  position: relative

  margin: 0 0.5em

  &:focus
    outline: none

  &:active
    top: 1px

.button-circle
  border: 2px solid $w
  border-radius: 3em
  background-color: transparent
  position: relative

  height: 3em
  width: 3em
  text-align: center
  margin: 0

  color: $w

  &:focus
    outline: none

  &:active
    top: 2px

.button-sending
  background-color: $b
  animation-name: pulsatingBackground
  animation-duration: 1s
  animation-iteration-count: infinite


@keyframes pulsatingBackground
  0%
    background-color: $g
  50%
    background-color: $bb
  100%
    background-color: $g