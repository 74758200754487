h1
  font-size: 4em

h2
  font-size: 3em

h3 
  font-size: 2em
  
p
  line-height: 2.25em
  color: $b

strong
  color: $b
  font-weight: 700

  &.lighter
    font-weight: 500

.subtitle
  margin: 0 0 1em 0
  line-height: 2em
  color: $w

.center
  text-align: center

a
  color: $bb
  text-decoration: none

  &:hover
    color: darken($bb, 15%)

.nodisplay
  display: none