footer
  color: $mg

  h2
    font-size: 1em

  p
    color: inherit
  a
    color: inherit

    &:hover
      color: darken($w, 20%)