ul.icon-line
  list-style-type: none
  padding: 0

  background-color: $w
  border-radius: 1em

  display: flex
  flex-wrap: wrap
  
  width: 100%

  li.icon-line-item
    border-right: 1px solid $lg
    margin: 1em 0
    padding: 0 2em

    color: $b

    text-align: center
    line-height: 2em
    flex-grow: 1

    &:last-child
      border-right: none

    svg
      color: $bb
      margin-right: 0.5em