.inline-image-gallery-fullscreen
  width: 100%
  height: 100%
  position: fixed
  z-index: 100
  top: 0
  left: 0

  background-color: rgba($b,0.9)

  display: flex

  .inline-image-gallery-fullscreen-closeButton
    position: absolute
    right: 2em
    top: 1em
    color: $w

  .inline-image-gallery-fullscreen-image
    flex-grow: 4
    align-self: center
    text-align: center
    max-height: 80vh

    img
      max-width: 100%
      max-height: 80vh

  .inline-image-gallery-fullscreen-button
    flex-grow: 0
    align-self: center
    margin: 0 2em

    button
      font-size: 2em
      color: $w
      
      
      height: 100px
  
.inline-image-gallery
  border-radius: 1em
  background-color: $b
  position: relative
  width: 100%
  padding-top: 56.25%
  overflow: hidden
  position: relative
  touch-action: none

  img
    position: absolute
    width: 100%
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: 10

  .inline-image-gallery-controls-left, .inline-image-gallery-controls-right
    z-index: 20
    position: absolute
    
    color: $w
    font-size: 0.8em
    font-weight: 600

  .inline-image-gallery-controls-right
    bottom: 2em
    right: 2em

  .inline-image-gallery-controls-left
    bottom: 2em
    left: 2em

  div.bottom-shadow
    position: absolute

    z-index: 10
    height: 6em
    width: 100%
    bottom: 0
    left: 0

    background: linear-gradient(180deg,  rgba($b, 0%) 0%, rgba($b, 50%) 100%)