.specs-row
  background-color: $br
  border-radius: 0.5em
  display: flex
  padding: 0.5em 1.5em
  &:nth-child(even)
    background-color: transparent
    

  p
    width: 50%
    margin: 0
    color: $b