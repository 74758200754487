
.grid-row
  margin: 30px -15px 30px -15px
  display: flex

  
    

  &.grid-row-align-center
    align-items: center

.column-one-third, .column-two-thirds, .column-one-half, .column-full, .column-one-fifth, .column-two-fifths, .column-three-fifths, .column-one-sixth
  margin: 0 15px

.column-one-sixth
  width: 16.66666666%

.column-one-fifth
  width: 20%

.column-two-fifths
  width: 40%

.column-three-fifths
  width: 60%

.column-one-third
  width: 33.33333333%

.column-two-thirds
  width: 66.66666666%

.column-one-half
  width: 50%

.column-full
  width: 100%

.column-align-right
  display: flex
  justify-content: flex-end

.column-align-middle
  display: flex
  align-items: middle

@include mobile
  .grid-row
    margin: -7.5px 0
    justify-content: space-between
    
    &:not(.grid-no-wrap)
      flex-wrap: wrap

  .column-one-third, .column-two-thirds, .column-one-half, .column-full, .column-one-fifth,  .column-two-fifths, .column-three-fifths
    width: 100%
    margin: 7.5px 0
  
  .column-one-sixth
    width: 32%
    margin: 7.5px 0
  
hr
  border: none
  margin: 5em 0
  
  @include tablet
    margin: 3em 0
  @include mobile
    margin: 1em 0

  &.small
    margin: 2em 0

    @include mobile
      margin: 0