.scattered-images
  display: flex
  flex-wrap: wrap

  > div
    
    > div
      float: left
      margin: 0.25em
      border-radius: 1em
      background-size: cover

  div.first
    width: 70%

    div
      position: relative

      &:nth-child(1)
        width: calc(100% - 0.5em)
        padding-top: 75%
        background-image: url('../img/ourstory/0.jpg')

      &:nth-child(2)
        width: calc(50% - 0.5em)
        padding-top: 28.125%
        overflow: hidden
        z-index: 30

        iframe
          position: absolute
          width: 100%
          top: 0
          left: 0
          bottom: 0
          right: 0
          z-index: 10
          margin: -1em -3em
          width: 140%
          height: 120%

      &:nth-child(3)
        width: calc(50% - 0.5em)
        padding-top: 37.25%
        background-image: url('../img/ourstory/2.jpg')

  div.second
    width: 30%
    padding-top: 2em
    
    div
      &:nth-child(1)
        width: calc(100% - 0.5em)
        padding-top: 75%
        background-image: url('../img/ourstory/3.jpg')
      
      &:nth-child(2)
        width: calc(100% - 0.5em)
        padding-top: 160%
        background-image: url('../img/ourstory/4.jpg')

