@font-face
  font-family: 'GTWalsheim'
  src: url('./GTWalsheim/GT-Walsheim-Regular.woff2') format('woff2'), url('./GTWalsheim/GT-Walsheim-Regular.woff') format('woff'), url('./GTWalsheim/GT-Walsheim-Regular.ttf') format('truetype')
  font-weight: normal
  font-style: normal
  font-display: fallback

@font-face
  font-family: 'GTWalsheim'
  src: url('./GTWalsheim/GT-Walsheim-Medium.woff2') format('woff2'), url('./GTWalsheim/GT-Walsheim-Medium.woff') format('woff'), url('./GTWalsheim/GT-Walsheim-Medium.ttf') format('truetype')
  font-weight: 500
  font-style: normal
  font-display: fallback

@font-face
  font-family: 'GTWalsheim'
  src: url('./GTWalsheim/GT-Walsheim-Bold.woff2') format('woff2'), url('./GTWalsheim/GT-Walsheim-Bold.woff') format('woff'), url('./GTWalsheim/GT-Walsheim-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal
  font-display: fallback