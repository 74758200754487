.person-profile
  text-align: center
  display: flex
  flex-wrap: wrap
  align-content: center

  p
    margin-top: 0.25em
    padding: 0
    flex-grow: 1
    
  .person-profile-image
    width: 100%
    padding-top: 100%
    background-size: cover
    border-radius: 0.5em
    flex-grow: 0

  &.person-profile-jakub .person-profile-image
    background-image: url(../img/us/jakub.jpeg)
  
  &.person-profile-marek .person-profile-image
    background-image: url(../img/us/marek.jpeg)

  &.person-profile-ondrej .person-profile-image
    background-image: url(../img/us/ondrej.jpeg)