
.calendar-filter
  border-radius: 0.5em
  background-color: $br
  display: inline-flex
  padding: 0.25em

  button
    display: block    

    border: none
    border-radius: 0.5em

    padding: 0.5em 4em
    font-weight: 400

    background-color: transparent

    &.selected
      background-color: white

    &:focus
      outline: none

  @include mobile
    display: block

    button
      width: 100%
      padding: 1em 4em
  
    
.calendar
  display: flex
  flex-wrap: wrap
  justify-content: center
  cursor: default
  padding: 2em

  .calendar-button-mobile
    margin: 1em 0
    width: 100%
    padding: 0.5em 0
    
  table
    border-spacing: 0
    flex-shrink: 0
    margin: 0 1.5em

    thead
      tr:first-child
        font-size: 1.25em
        
        th
          position: relative
          font-weight: 700

          button
            position: absolute
            font-size: 1.25em
            margin-top: -3px

            &:active
              top: inherit
              margin-top: -1px
          
            &.button-left
              left: 0

            &.button-right
              right: 0

      th
        font-weight: 500
        width: 3em
        height: 3em

    tbody
      tr 
        height: 3em

      td
        padding: 0
        border: 1px solid transparent
        position: relative
        
        // ANY calendar item
        span
          box-sizing: border-box

          display: block
          border: 1px solid transparent
          position: absolute
          top: 0

          z-index: 10

          width: 3em
          height: 3em
          line-height: 3em

        // SATURDAY 
        &.calendar-saturday
          cursor: pointer
          
          &.calendar-selected-week:not(.calendar-saturday-selected-previousweek):not(.calendar-hovered-week)
            span
              border: none
              background-color: $bb
              border-radius: 3em
              color: $w

              z-index: 10
              
            &:not(.calendar-saturday-hovered-previousweek)
              background-color: transparent

              &:before
                display: block
                position: absolute

                width: calc(50% + 1px)
                height: calc(100% + 2px)

                top: -1px
                left: 50%

                z-index: 3
                background-color: $br
                content: " "

        &.calendar-disabled
          color: $lg
        
        @include desktop
          &.calendar-hovered-week
            background-color: $br

            &.calendar-saturday
              span
                border: 1px solid $bb
                background-color: $w
                color: $b
                border-radius: 3em

            &.calendar-last-day-hovered
              border-top-right-radius: 3em
              border-bottom-right-radius: 3em

            &.calendar-saturday:not(.calendar-saturday-selected-previousweek)
              background-color: transparent

              &:before
                display: block
                position: absolute

                width: calc(50% + 1px)
                height: calc(100% + 2px)

                top: -1px
                left: 50%

                z-index: 3
                background-color: $br
                content: " "

        &.calendar-selected-week
          background-color: $br

          &.calendar-last-day-selected
            border-top-right-radius: 3em
            border-bottom-right-radius: 3em
          
        &.calendar-hoverable
          cursor: pointer

.calendar-yachtline
  background-color: $br
  margin-bottom: 2em
  padding: 1em
  border-radius: 1em

  display: flex
  justify-content: space-between

  > div
    display: flex
    align-items: center

  .calendar-yachtline-boatimage
    border-radius: 1em
    width: 8em
    height: 6em
    background-size: cover

    &.nalu
      background-image: url(../../img/nalu/1.jpg)

    &.keiki
      background-image: url(../../img/keiki/e1.jpg)

  h3
    font-size: 1.25em
    padding: 0.5em 2em
    border-right: 1px solid darken($br, 10%)
    text-transform: capitalize

  p.price
    padding: 0.5em 2em
    font-size: 1.25em
  
  .button-group button.button-white
    text-transform: capitalize
  
  

  @include mobile
    flex-wrap: wrap
    padding: 0 1em 1em 1em
    justify-content: space-between

    .calendar-yachtline-boatimage
      margin: 1em 0 1em 0

    h3
      padding: 0.5em 1em

    p.price
      padding: 0.5em 1em
    
    .button-group
      display: flex
      justify-content: space-between
      width: 100%

      button
        font-size: 0.9em

    .calendar-book-input
      display: flex
      flex-wrap: wrap

      input
        width: 100%
        margin: 0.5em 0

      button
        margin: 0.5em 0
        font-size: 1em 
