@import './fonts/font.sass'
@import 'breakpoints'
@import 'colors'
@import 'columns'

@import 'header'
@import 'footer'

@import 'components/buttons'
@import 'components/inputs'
@import 'components/typography'

@import 'components/specs'

@import 'components/calendar'

@import 'components/inline-image-gallery'
@import 'components/icon-line'
@import 'components/testimonials'

  
@import 'scattered-images'
@import 'person-profile'

@import 'section'

body
  font-family: 'GTWalsheim', sans-serif
  margin: 0
  background-color: $b
  box-sizing: border-box

  input, button
    font-family: 'GTWalsheim', sans-serif

@include mobile
  body
    font-size: 12px

main, header
  color: $b

.nomargin
  margin: 0

.container
  padding: 30px
  margin: 0 auto

  @include desktop
    max-width: 1090px
  
  @include mobile
    padding: 15px


ul
  list-style-type: '\25CF'
  
  li
    padding-left: 1em
    color: $bb
    margin: 1.5em 0

    span
      color: $b


.image-full
  border-radius: 1em
  width: 100%