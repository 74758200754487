header
  background-color: $w

  nav
    display: flex
    justify-content: space-between
    align-items: center

    a
      text-decoration: none
      color: $g

    ul
      list-style-type: none
      margin: 0
      padding: 0
      display: flex
      align-items: center

      li
        margin: 0 1em

        &:first-child
          margin: 0 1em 0 0
          padding-left: 0em
          
          a.main
            font-weight: bold
            text-transform: uppercase

    .button-group
      margin: 0 2em

      &:last-child
        margin-right: 0

    // Mobile version
    @include mobile
      display: block
      
      ul
        display: block

        li
          margin: 0.25em 0
  &.mobile

    div.header-content
      height: calc(100vh - 30px)
      display: flex
      align-items: flex-end
      flex-wrap: wrap

    nav
      background-color: $b
      margin:  -15px -15px -15px -100%
      padding: 6em 0 0 0
      position: fixed
      height: 100vh
      z-index: 100
      transition: margin 400ms
      
      a
        color: $w

      li
        margin: 0 0 1.5em 0 
        font-size: 1.25em
        
        &:first-child
          margin-bottom: 1.5em
        
      > div.button-group
        margin: 1.5em 15px 0 15px
        display: flex
        flex-wrap: wrap
        > a
          margin-right: 1em
          font-size: 1.25em

        > div
          width: 100%
          margin: 1.5em 0 0 0
    
    button#mobile-menu-bars
      display: block
      padding: 0
      top: 15px
      left: 15px
      
      margin: 0

      font-size: 1.5em
      position: absolute
      z-index: 120

      &:active
        // top: auto
        margin-top: 2px

    &.visible
      nav
        margin-left: -15px
        padding-left: 15px

      button#mobile-menu-bars
        position: fixed
        color: $w



  &.header-image-background
    background-size: cover
    background-position: center 80%
    position: relative

    color: $w
    div
      z-index: 20
    
    .bottom-shadow
      position: absolute

      z-index: 10
      height: 10em
      width: 100%
      bottom: 0
      left: 0

      background: linear-gradient(180deg,  rgba($b, 0%) 0%, $b 100%)
    a
      color: $w

      &:hover
        color: darken($w, 10%)

    nav
      button.button-rounded
        background-color: $w
        color: $b

        &:hover
          background-color: $w
          color: $b

    &.header-image-gallery
      div.grid-row
        &:first-of-type
          height: 20em
          display: flex
          align-items: center
        
        button
          display: flexbox
.mobile
  div.grid-row
    .column-one-half.column-align-right.column-align-middle
      justify-content: flex-start

  &.header-image-gallery
    div.grid-row
      &:first-of-type
        width: 100%